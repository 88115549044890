<template>
  <header>
    <nav>
      <div class="logo">
        <a href="#app">
        <img src="@assets/image/svg/Logo.svg" alt="logo" />
        </a>
      </div>
      <div class="opciones-menu-web">
        <ul>
          <li><a href="#quienes-somos">¿Quienes somos?</a></li>
          <li><a href="#servicios">Servicios</a></li>
          <li><a href="#beneficios">Beneficios</a></li>
          <li><a href="#socios">Socios comerciales</a></li>
          <li><a href="#contacto">Contacto</a></li>
        </ul>
      </div>
      <div class="menu-movil" @click="activedMenuMovil">
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
      </div>
    </nav>
  </header>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'Header',
  components: {
  },
  data() {
    let flagWeb = true
    let flagMovil = false

    return {
      flagWeb,
      flagMovil
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler)
    this.myEventHandler()
  },
  methods: {
    myEventHandler() {
      if (window.innerWidth < 1280) {
        this.flagWeb = false
      } else {
        this.flagWeb = true
      }

      if (window.innerWidth < 768) {
        this.flagMovil = true
      } else {
        this.flagMovil = false
      }
    },
    ...mapMutations(['activedMenuMovil'])
  }
}
</script>

<style scoped>
header  {
  padding: 0px 15px;
  box-shadow: 0px 3px 20px #00000029;
  background-color: #FAFAFA;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
}   
header nav {
    max-width: 1280px;
    display: flex;
    align-items: center;
    height: 70px;
    margin: 0 auto;
}
header nav .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
header nav .logo img {
  height: 61px;
  object-fit: cover;
}
header nav .opciones-menu-web{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header nav .opciones-menu-web ul {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
header nav .opciones-menu-web ul li{
  float: left;
  margin-left: 26px;
}
header nav .opciones-menu-web ul li a{
  font-size: 16px;
  font-weight: bold;
  color: #404040;
}
header nav .menu-movil {
  display: none;
}
header nav .menu-movil .lines {
  width: 30px;
  height: 4px;
  background: #A3A3A3;
  margin-bottom: 6px;
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
}
header nav .menu-movil .lines:last-child {
  margin-bottom: 0px;
}

@media (max-width: 1279px) {
  header nav .menu-movil {
    display: none;
  }
  header nav .opciones-menu-web {
    display: none;
  }
  header nav {
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  header nav .logo {
    margin-right: auto;
  }
}
</style>
