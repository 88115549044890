<template>
  <div class="contenedor-contactanos" id="contacto">
    <picture>
      <source
        media="(max-width: 767px)"
        srcset="@assets/image/contactanos/MovilContactanos.png"
        type="image/png"
      />
      <source
        media="(max-width: 1279px)"
        srcset="@assets/image/contactanos/TabletContactanos.png"
        type="image/png"
      />
      <img
        src="@assets/image/contactanos/WebContactanos.png"
        class="img-banner"
      />
    </picture>
    <div class="contenedor-formulario">
      <div class="form-group">
        <input
          type="text"
          :class="nombre != '' ? 'lleno' : ''"
          class="form-control"
          id="nombre"
          v-model="nombre"
        />
        <span
          @click="activarInput('nombre')"
          :class="nombre != '' ? 'float' : ''"
          class="placeholder"
          >Nombre</span
        >
      </div>
      <div class="form-group">
        <input
          type="text"
          :class="correo != '' ? 'lleno' : ''"
          class="form-control"
          id="correo"
          v-model="correo"
        />
        <span
          @click="activarInput('correo')"
          :class="correo != '' ? 'float' : ''"
          class="placeholder"
          >Correo</span
        >
      </div>
      <div class="form-group">
        <input
          type="text"
          :class="telefono != '' ? 'lleno' : ''"
          class="form-control"
          id="telefono"
          v-model="telefono"
        />
        <span
          @click="activarInput('telefono')"
          :class="telefono != '' ? 'float' : ''"
          class="placeholder"
          >Teléfono</span
        >
      </div>
      <div class="form-group">
        <input
          type="text"
          :class="asunto != '' ? 'lleno' : ''"
          class="form-control"
          id="asunto"
          v-model="asunto"
        />
        <span
          @click="activarInput('asunto')"
          :class="asunto != '' ? 'float' : ''"
          class="placeholder"
          >Asunto</span
        >
      </div>
      <div class="form-group">
        <textarea
          :class="comentario != '' ? 'lleno' : ''"
          class="form-control area"
          id="comentario"
          v-model="comentario"
        ></textarea>
        <span
          @click="activarInput('comentario')"
          :class="comentario != '' ? 'float' : ''"
          class="placeholder"
          >Comentario</span
        >
      </div>
      <div class="contenedor-boton">
        <button class="btn-enviar">Envíar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contactanos",
  data() {
    return {
      nombre: "",
      correo: "",
      telefono: "",
      asunto: "",
      comentario: "",
    };
  },
  methods: {
    activarInput(elemento) {
      document.getElementById(elemento).focus();
    },
  },
};
</script>

<style scoped>
.contenedor-contactanos {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 31px;
}
.contenedor-contactanos .contenedor-formulario {
  width: 590px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.contenedor-contactanos .contenedor-formulario .form-group {
  width: 100%;
  position: relative;
}
.contenedor-contactanos .contenedor-formulario .form-group .form-control {
  width: 100%;
  color: #404040;
  font-size: 16px;
  outline: none;
  height: 45px;
  background: #ffffff;
  border: 2px solid #dadee3;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 12px 15px;
  transition: all 0.3s ease-in-out;
}
.contenedor-contactanos .contenedor-formulario .form-group .form-control.area {
  width: 100%;
  height: 110px;
  resize: none;
}
.contenedor-contactanos .contenedor-formulario .form-group .form-control.lleno {
  padding: 21px 15px 5px 15px;
  border-color: #48a7ff;
}
.contenedor-contactanos .contenedor-formulario .form-group .form-control:focus {
  border-color: #48a7ff;
}
.contenedor-contactanos .contenedor-formulario .form-group .placeholder {
  font-size: 16px;
  color: #404040;
  position: absolute;
  top: 12px;
  left: 15px;
  transition: all 0.3s ease-in-out;
}
.contenedor-contactanos .contenedor-formulario .form-group .placeholder.float {
  font-size: 12px;
  top: 5px;
  left: 15px;
  transition: all 0.3s ease-in-out;
}
.contenedor-contactanos .contenedor-formulario .contenedor-boton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contenedor-contactanos .contenedor-formulario .contenedor-boton .btn-enviar {
  width: 190px;
  height: 45px;
  background-color: #059abc;
  border-radius: 10px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.contenedor-contactanos
  .contenedor-formulario
  .contenedor-boton
  .btn-enviar:hover {
  background-color: #037e9a;
}
.contenedor-contactanos
  .contenedor-formulario
  .contenedor-boton
  .btn-enviar:active {
  background-color: #18bade;
}
@media (max-width: 1279px) {
  .contenedor-contactanos {
    flex-direction: column;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }
  .contenedor-contactanos .contenedor-formulario {
    margin-left: 0;
    width: 100%;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  
}
</style>