<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html {
  color: #616161;
  font-size: 16px;
}
</style>