<template>
  <div class="footer">
    <div class="container-footer">
      <div class="logo">
        <img src="@assets/image/svg/Logo.svg" alt="logo" />
      </div>
      <div class="opciones-menu">
        <div class="opciones">
          <ul>
            <li><a href="#">¿Quienes somos?</a></li>
            <li><a href="#">Servicios</a></li>
            <li><a href="#">Beneficios</a></li>
          </ul>
        </div>
        <div class="opciones">
          <ul>
            <li><a href="#">Socios comerciales</a></li>
            <li><a href="#">Contacto</a></li>
          </ul>
        </div>
      </div>
      <div class="redes-sociales">
        <div class="contenedor-redes">
          <i class="icono-facebook"></i>
          <i class="icono-instagram"></i>
          <i class="icono-twitter-1"></i>
          <i class="icono-youtube-play"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  height: 180px;
  width: 100%;
  margin-top: 50px;
  background: #fafafa;
  box-shadow: 0px -3px 20px #00000029;
  display: flex;
  align-items: center;
}
.footer .container-footer {
  max-width: 1280px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.container-footer .opciones-menu {
  display: flex;
}
.container-footer .opciones-menu .opciones:first-child {
  margin-right: 75px;
  margin-left: 53px;
}
.container-footer .opciones-menu .opciones ul{
  list-style: none;
}
.container-footer .opciones-menu .opciones ul li {
  margin-bottom: 10px;
}
.container-footer .opciones-menu .opciones ul li a{
  color: #1D1D1D;
  margin-bottom: 10px;
  font-size: 16px;
}
.container-footer .redes-sociales {
  width: 100%;
  position: absolute;
  right: 0;
  height: 122px;
}
.container-footer .redes-sociales .contenedor-redes {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.container-footer .redes-sociales .contenedor-redes i {
  color: #059ABC;
  text-shadow: 1px 1px 3px #00000029;
  font-size: 25px;
  cursor: pointer;
}
@media (max-width: 1279px) {
  .footer {
    padding: 0 15px;
  }
  .container-footer .redes-sociales {
    bottom: 0;
    height: auto;
    right: 90px;
  }
  .container-footer .redes-sociales .contenedor-redes {
    height: auto;
  }
}

@media (max-width: 767px) {
  
}
</style>