<template>
  <div class="contenedor-banner">
    <picture>
      <source
        media="(max-width: 767px)"
        srcset="@assets/image/BannerMovil.png"
        type="image/png"
      />
      <source
        media="(max-width: 1279px)"
        srcset="@assets/image/BannerTablet.png"
        type="image/png"
      />
      <img src="@assets/image/BannerWeb.png" class="img-banner" />
    </picture>
    <div class="contenedor-texto">
      <h1 class="titulo-principal">¡Tecnologíza tu Empresa!</h1>
      <p class="subtitulo-banner">
        Conoce nuestras soluciones y automatiza los procesos de tu empresa.
      </p>
      <button type="button" class="btn-demo">Solicita tu demo</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPrincipal",
  components: {},
};
</script>

<style scoped>
.contenedor-banner {
  width: 100%;
  position: relative;
}
.contenedor-texto {
  position: absolute;
  top: 140px;
  left: 30px;
  width: 720px;
  display: flex;
  flex-direction: column;
}
.contenedor-texto .titulo-principal {
  font-size: 58px;
  color: #037e9a;
  font-weight: bold;
  margin: 0;
}
.contenedor-texto .subtitulo-banner {
  font-size: 28px;
  font-weight: bold;
  color: #616161;
  margin: 10px 0 30px 0;
}
.contenedor-texto .btn-demo {
  width: 210px;
  height: 45px;
  background-color: #059abc;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.contenedor-texto .btn-demo:hover {
  background-color: #037e9a;
}
.contenedor-texto .btn-demo:active {
  background-color: #18bade;
}
@media (max-width: 1279px) {
  .contenedor-texto .titulo-principal {
    font-size: 48px;
  }
  .contenedor-texto .subtitulo-banner {
    font-size: 20px;
    width: 420px;
  }
}

@media (max-width: 767px) {
  
}
</style>