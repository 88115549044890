<template>
  <div class="contenedor-servicios" id="servicios">
    <h2 class="titulo">Nuestros servicios</h2>
    <div class="carrusel">
      <div class="contenedor-items">
        <div class="item">
          <img src="@assets/image/servicios/CRM.png" alt="CRM" />
          <div class="contenedor-texto">
            <p class="titulo-item">CRM</p>
            <p class="descripcion">
              Automatiza tu flujo de ventas con nuestro CRM en la nube y haz más
              eficiente tu proceso comercial.
            </p>
          </div>
        </div>
        <div class="item">
          <img
            src="@assets/image/servicios/AplicacionesMoviles.png"
            alt="Aplicaciones móviles"
          />
          <div class="contenedor-texto">
            <p class="titulo-item">Aplicaciones móviles</p>
            <p class="descripcion">
              Facilita a tus clientes que adquieran tus productos de una manera
              fácil, rápida y sencilla, desarrollando una aplicación que te
              permita maximizar tus ventas de una manera exponencial.
            </p>
          </div>
        </div>
        <div class="item">
          <img
            src="@assets/image/servicios/DesarrollosEspeciales.png"
            alt="Desarrollos especiales"
          />
          <div class="contenedor-texto">
            <p class="titulo-item">Desarrollos especiales</p>
            <p class="descripcion">
              ¿Tienes alguna idea que no haz llevado a cabo? Acércate a nosotros y
              podemos hacer esa idea una realidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Servicios",
};
</script>

<style scoped>
.contenedor-servicios {
  width: 100%;
  margin-top: 50px;
}
.contenedor-servicios .titulo {
  font-size: 38px;
  font-weight: bold;
  color: #404040;
  margin-bottom: 30px;
}
.contenedor-servicios .contenedor-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contenedor-servicios .contenedor-items .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 6px #00000033;
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 370px;
}
.contenedor-servicios .contenedor-items .item img {
  width: 100%;
  height: 270px;
}
.contenedor-servicios .contenedor-items .item .contenedor-texto {
  width: 100%;
  height: 135px;
  padding: 10px 22px 0 22px;
}
.contenedor-servicios .contenedor-items .item .contenedor-texto .titulo-item {
  margin: 0 0 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #404040;
}
.contenedor-servicios .contenedor-items .item .contenedor-texto .descripcion {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  line-height: 18px;
}
@media (max-width: 1279px) {
  .contenedor-servicios {
    padding: 0 0 0 20px;
    margin-top: 45px;
  }
  .contenedor-servicios .titulo {
    font-size: 34px;
  }
  .contenedor-servicios .contenedor-items{
    max-width: 1180px;
    width: 1180px;
  }
  .carrusel {
    width: 100%;
    overflow: auto;
    height: 430px;
  }
}

@media (max-width: 767px) {
  
}
</style>