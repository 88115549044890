<template>
    <Header></Header>
    <section class="page-container">
      <div class="components-container">
        <slot />
      </div>
    </section>
    <Footer></Footer>
</template>

<script>
import Header from '@layouts/Header.vue'
import Footer from '@layouts/Footer.vue'

export default {
  name: "Layout",
  components: {
    Header,
    Footer
  }
}
</script>

<style>
  html, body {
    height: calc(100vh);
    overflow-y: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .page-container .components-container {
    background-color: #FFFFFF;
    max-width: 1280px;
    margin: 0 auto;
  }

  @media (max-width: 1279px) {
  }
</style>