<template>
  <div class="contenedor-socios" id="socios">
    <div class="contenedor-texto">
      <h2 class="titulo">Socios Comerciales</h2>
      <p class="subtitulo">
        Incrementa tus ingresos y brindale a tus clientes una herramienta de
        calidad, para la automatización de sus procesos Beneficios
      </p>
      <ul class="lista">
        <li>
          Genera comisiones por la venta de cualquier servicio que ofrece
          DETECS.
        </li>
        <li>
          Incrementa rápidamente tus ventas con nuestro apoyo en el seguimiento
          del prospecto.
        </li>
        <li>
          Aumenta tu cartera de clientes con el respaldo de una gran empresa.
        </li>
        <li>Tu vendes, nosotros implementamos.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SociosComerciales",
};
</script>

<style scoped>
.contenedor-socios {
  width: 100%;
  padding: 40px 0;
  background: url("~@assets/image/socios/WebSocios.png");
  background-size: cover;
  height: 353px;
}
.contenedor-socios .contenedor-texto {
  color: #ffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.contenedor-socios .contenedor-texto .titulo {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 20px;
}
.contenedor-socios .contenedor-texto .subtitulo {
  max-width: 636px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.contenedor-socios .contenedor-texto .lista {
  text-align: left;
  width: 686px;
}
.contenedor-socios .contenedor-texto .lista li {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 1279px) {
  .contenedor-socios .contenedor-texto .titulo {
    font-size: 34px;
  }
  .contenedor-socios .contenedor-texto .subtitulo {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  
}
</style>