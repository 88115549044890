<template>
  <div class="contenedor-beneficios" id="beneficios">
    <h2 class="titulo">Beneficios de nuestros servicios</h2>
    <div class="contenedor-items">
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/CreacionHerramientas.svg"
          alt="Creación de herramientas"
        />
        <p>Creación de herramientas a la medida de tus procesos.</p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/ProcesosInteligentes.svg"
          alt="Procesos inteligentes"
        />
        <p>Procesos inteligentes, para la optimización de recursos.</p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/ControlCentralizado.svg"
          alt="Control centralizado"
        />
        <p>
          Control centralizado de tú operación en una plataforma en la nube.
        </p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/DesarrolloImplementacion.svg"
          alt="Desarrollo e implementación"
        />
        <p>Desarrollo e implementación en tiempo récord.</p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/EstandarizacionProcesos.svg"
          alt="Estandarización de procesos"
        />
        <p>Estandarización de procesos con nuestras herramientas integrales.</p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/RealizaOperaciones.svg"
          alt="Realiza tus operaciones"
        />
        <p>
          Realiza tus operaciones desde cualquier dispositivo con acceso a
          internet.
        </p>
      </div>
      <div class="item">
        <div class="circulo"></div>
        <img
          src="@assets/image/svg/beneficios/TodaInformacion.svg"
          alt="Información en la nube"
        />
        <p>Toda tu información respaldada en la nube.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Beneficios",
};
</script>

<style scoped>
.contenedor-beneficios {
  width: 100%;
  margin-top: 50px;
}
.contenedor-beneficios .titulo {
  font-size: 38px;
  font-weight: bold;
  color: #404040;
  margin-bottom: 30px;
}
.contenedor-beneficios .contenedor-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.contenedor-beneficios .contenedor-items .item {
  box-shadow: 0px 4px 6px #00000033;
  background-color: #ffffff;
  width: 270px;
  height: 230px;
  padding: 16px 19px 0 19px;
  margin-bottom: 50px;
  position: relative;
}
.contenedor-beneficios .contenedor-items .item p {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 36px;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 19px 0 19px;
  line-height: 25px;
  height: 94px;
}
.contenedor-beneficios .contenedor-items .item .circulo {
  width: 80px;
  height: 80px;
  background-color: #9bd6e4;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 20px;
}
.contenedor-beneficios .contenedor-items .item img {
  z-index: 1;
  position: absolute;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(1) {
  width: 89px;
  height: 89px;
  left: 63px;
  top: 16px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(2) {
  width: 72px;
  height: 72px;
  left: 70px;
  top: 24px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(3) {
  width: 83px;
  height: 83px;
  left: 65px;
  top: 12px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(4) {
  width: 67px;
  height: 67px;
  left: 70px;
  top: 27px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(5) {
  width: 63px;
  height: 63px;
  left: 70px;
  top: 25px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(6) {
  width: 73px;
  height: 73px;
  left: 72px;
  top: 25px;
}
.contenedor-beneficios .contenedor-items .item img:nth-child(7) {
  width: 89px;
  height: 89px;
  left: 62px;
  top: 20px;
}
@media (max-width: 1279px) {
  .contenedor-beneficios {
    margin-top: 30px;
    padding: 0 20px;
  }
  .contenedor-beneficios .titulo {
    font-size: 34px;
  }
  .contenedor-beneficios .contenedor-items .item {
    width: 222px;
    height: 200px;
  }
  .contenedor-beneficios .contenedor-items .item p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  
}
</style>