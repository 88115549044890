import { createRouter, createWebHistory } from 'vue-router'
import Home from '@views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  let title = to.meta.title || ''
  document.title = `DETECS A&G | ${title}`

  return next()
})

export default router
