<template>
<Layout>
  <Banner></Banner>
  <QuienesSomos></QuienesSomos>
  <Servicios></Servicios>
  <Beneficios></Beneficios>
  <SociosComerciales></SociosComerciales>
  <Contactanos></Contactanos>
</Layout>
</template>

<script>
import Layout from '@layouts/Layout.vue'
import Banner from '@components/Banner.vue'
import QuienesSomos from '@components/QuienesSomos.vue'
import Servicios from '@components/Servicios.vue'
import Beneficios from '@components/Beneficios.vue'
import SociosComerciales from '@components/SociosComerciales.vue'
import Contactanos from '@components/Contacto.vue'

export default {
  name: 'Home',
  components: {
    Layout,
    Banner,
    QuienesSomos,
    Servicios,
    Beneficios,
    SociosComerciales,
    Contactanos
  }
}
</script>
