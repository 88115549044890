<template>
  <div class="contenedor-quienes-somos" id="quienes-somos">
    <picture>
      <source
        media="(max-width: 767px)"
        srcset="@assets/image/quienes-somos/QuienesSomosMovil.png"
        type="image/png"
      />
      <source
        media="(max-width: 1279px)"
        srcset="@assets/image/quienes-somos/QuienesSomosTablet.png"
        type="image/png"
      />
      <img
        src="@assets/image/quienes-somos/QuienesSomosWeb.png"
        class="img-banner"
      />
    </picture>
    <div class="contenedor-texto">
      <h2 class="titulo">¿Quienes somos?</h2>
      <p class="subtitulo">
        DETECS A&G es una empresa mexicana que nace con la finalidad de ofrecer
        servicios enfocados en el desarrollo y consultoría de tecnología,
        creamos herramientas funcionales para la gestión de pequeñas y medianas
        empresas, adaptándonos a sus procesos, entregando soluciones a la medida
        de una alta calidad, para el control de su operación.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuienesSomos",
};
</script>

<style scoped>
.contenedor-quienes-somos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}
.contenedor-quienes-somos img {
  margin-right: 35px;
}
.contenedor-quienes-somos .contenedor-texto {
  display: flex;
  flex-direction: column;
}
.contenedor-quienes-somos .contenedor-texto .titulo {
  font-size: 38px;
  font-weight: bold;
  color: #404040;
}
.contenedor-quienes-somos .contenedor-texto .subtitulo {
  font-size: 18px;
  color: #404040;
  margin-top: 20px;
}
@media (max-width: 1279px) {
  .contenedor-quienes-somos {
    flex-direction: column-reverse;
    margin-top: 45px;
    display: flex;
    justify-content: center;
  }
  .contenedor-quienes-somos .contenedor-texto .titulo {
    font-size: 34px;
  }
  .contenedor-quienes-somos .contenedor-texto {
    margin-bottom: 20px;
    padding: 0 20px
  }
  .contenedor-quienes-somos img {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  
}
</style>